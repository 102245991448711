<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <b-form-group label-cols="3" :label="'Link|Lien'|tr" v-if="component.useBinding">
            <select-cms-item-field :item-type="block.itemType" v-model="component.linkField" field-type="any-link"/>
        </b-form-group>

        <b-form-group v-else :label="'Link|Lien'|tr">
            <input-link v-model="component.link"/>
        </b-form-group>

        <slot></slot>
    </properties-component>
</template>

<script>
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";

// properties-component-link #hot-reload-debug
export default {
    name: `properties-component-link`,
    components: {InputLink, SelectCmsItemField, PropertiesComponent},
    extends: PropertiesComponent
}
</script>

<style lang="scss" scoped>
</style>
