<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-link title="Link|Lien" :value="block.link" v-bind="componentProps"/>
        <properties-component-image title="Image" :value="block.image" v-bind="componentProps"/>
        <properties-component-text title="Title|Titre" :value="block.title" v-bind="componentProps"/>
        <properties-component-text title="Subtitle|Sous-titre" :value="block.subtitle" v-bind="componentProps"/>
        <properties-component-text title="Description" :value="block.description" v-bind="componentProps"/>
        <properties-component-button title="Button" :value="block.button" v-bind="componentProps"/>

        <template #layout>
            <b-form-group>
                <btn-responsive-prop v-model="block" field="borderRadius" no-label/>
                <input-border-radius v-model="block.mediaProxy.borderRadius"/>
            </b-form-group>
        </template>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentLink from "@/components/editor/properties/components/properties-component-link.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";

// properties-block-list-item #hot-reload-debug
export default {
    name: `properties-block-list-item`,
    components: {PropertiesComponentLink, InputBorderRadius, PropertiesComponentImage, PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
